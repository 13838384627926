
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBxZsx26fKlStvTnKOdGEcEIgopIQVLB-U",
    authDomain: "alephbeth-882a4.firebaseapp.com",
    projectId: "alephbeth-882a4",
    storageBucket: "alephbeth-882a4.appspot.com",
    messagingSenderId: "989761399084",
    appId: "1:989761399084:web:3134454abe0af2cf3a2489",
    measurementId: "G-V8QVC6C1LX"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app); // Export analytics instance

export default app; // Export app if needed elsewhere